import * as React from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import { Paper } from "@mui/material";
import { TiDelete } from "react-icons/ti";
import { token, APIPath } from "../../config/api";
import PackageDetail from "./AttractionPreview";
import './Attraction.css';

const StyledTableContainer = styled(TableContainer)(({ theme, open }) => ({
  width: open ? "80%" : "94%",
  transition: "width 0.3s ease",
  paddingTop: "7px",
  marginLeft: open ? "243px" : "69px",
  position: "relative",
  borderRadius: "0px",
  overflow: "hidden",
  marginBottom: "20px",
  boxShadow: "none",
}));

const CreatePackageForm = ({ open, close, loadPackagesList,
  attractionEditedId,
  titleP, descriptionP,
  imageCoverP, imageBannerP,
  countryP, countryName,
  cityP, cityName,
  cancellationRefundPolicyP,
  inclusionP, exclusionP,
  categoryP, categoryNameP,
  exceptions, Price
}) => {
  document.body.style.overflow = 'hidden';

  const [title, setTitle] = React.useState(titleP || '');
  const [description, setDescription] = React.useState(descriptionP || '');
  const [imageCover, setImageCover] = React.useState(imageCoverP || '');
  const [coverPreview, setCoverPreview] = React.useState(imageCoverP || '')
  const [imageBanner, setImageBanner] = React.useState(imageBannerP || '');
  const [bannerPreview, setBannerPreview] = React.useState(imageBannerP || '');
  const [country, setCountry] = React.useState(null)
  const [fromCountry, setFromCountry] = React.useState(countryP || "");
  const [fromCountryName, setFromCountryName] = React.useState(countryName || '');
  const [city, setCity] = React.useState(null)
  const [place, setPlace] = React.useState(cityP || "");
  // const [expectation, setExpectation] = React.useState([''])
  const [placeName, setPlaceName] = React.useState(cityName || '');
  const [categoryData, setCategoryData] = React.useState(null);
  const [category, setCategory] = React.useState(categoryP || '');
  const [categoryName, setCategoryName] = React.useState(categoryNameP || '')
  // const [location, setLocation] = React.useState('');
  // const [Ldescription, setLdescription] = React.useState('');
  // const [openTime, setOpenTime] = React.useState('');
  const [inclusions, setInclusions] = React.useState(inclusionP || ['']);
  const [exclusions, setExclusions] = React.useState(exclusionP || ['']);
  // const [priceDescription, setPriceDescription] = React.useState([''])
  // const [adultsPrice, setAdultsPrice] = React.useState(adultPriceP || '');
  // const [childPrice, setChildPrice] = React.useState(childPriceP || '')
  const [currency, setCurrency] = React.useState("AED");
  const [cancellationPolicy, setCancellationPolicy] = React.useState(cancellationRefundPolicyP || '');
  const [expectation, setExpectation] = React.useState(exceptions || [
    {
      location: '', description: '',
      // time: '' 
    }
  ]);
  const handleLocationChange = (id, value) => {
    const updatedExpectation = expectation.map((item, index) =>
      index === id ? { ...item, location: value } : item
    );
    setExpectation(updatedExpectation);
  };

  const handleLdescriptionChange = (id, value) => {
    const updatedExpectation = expectation.map((item, index) =>
      index === id ? { ...item, description: value } : item
    );
    setExpectation(updatedExpectation);
  };

  // const handleOpenTimeChange = (id, value) => {
  //   const updatedExpectation = expectation.map((item, index) =>
  //     index === id ? { ...item, time: value } : item
  //   );
  //   setExpectation(updatedExpectation);
  // };
  React.useEffect(() => {
    fetch(`${APIPath}/api/v1/categories?type=attraction`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
      mode: "cors",
    })
      .then((res) => res.json())
      .then((data) => {
        setCategoryData(data.data)
      })
      .catch((error) => {
        alert('Error fetching Category list:', error);

      });
  }, [])
  const handleCategoryChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const selectedCategoryName = e.target.options[selectedIndex].text;
    setCategory(e.target.value);
    setCategoryName(selectedCategoryName);
  };
  const [priceDescription, setPriceDescription] = React.useState(Price || [
    { description: '', adultPrice: '', childPrice: '', currency: 'AED' }
  ]);
  const handleDescriptionChange = (id, value) => {
    const updatedPriceDescription = priceDescription.map((item, index) =>
      index === id ? { ...item, description: value } : item
    );
    setPriceDescription(updatedPriceDescription);
  };

  const handleAdultPriceChange = (id, value) => {
    const updatedPriceDescription = priceDescription.map((item, index) =>
      index === id ? { ...item, adultPrice: value } : item
    );
    setPriceDescription(updatedPriceDescription);
  };
  const handleAddException = () => {
    setExpectation([...expectation, ''])
  }
  const deleteException = (index) => {
    if (expectation.length > 1) {
      const updatedExceptions = expectation.filter((val, id) => id !== index);
      setExpectation(updatedExceptions)
    }
  }
  const handleChildPriceChange = (id, value) => {
    const updatedPriceDescription = priceDescription.map((item, index) =>
      index === id ? { ...item, childPrice: value } : item
    );
    setPriceDescription(updatedPriceDescription);
  };
  const handleCurrencyChange = (id, value) => {
    const updatedPriceDescription = priceDescription.map((item, index) =>
      index === id ? { ...item, currency: value } : item
    );
    setPriceDescription(updatedPriceDescription);
  };

  const Id = "65b20828d1cdfbe9749eb653";
  React.useEffect(() => {
    fetch(`${APIPath}/api/v1/agency/country?continent=${Id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "GET",
      mode: "cors",
    })
      .then((res) => res.json())
      .then((data) => {
        setCountry(data.data);
      })
      .catch((err) => alert(err));

  }, [Id])

  React.useEffect(() => {
    if (fromCountry) {
      fetch(`${APIPath}/api/v1/agency/city?country=${fromCountry}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        method: "GET",
        mode: "cors",
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data.data)
          setCity(data.data);
        })
        .catch((err) => alert(err));
    }
  }, [fromCountry]);

  const handleFromCountryChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const selectedCategoryName = e.target.options[selectedIndex].text;
    setFromCountry(e.target.value);
    setFromCountryName(selectedCategoryName);
  };
  const handlePlaceChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const selectedCategoryName = e.target.options[selectedIndex].text;
    setPlace(e.target.value);
    setPlaceName(selectedCategoryName);
  };

  const [validBannerImage, setValidBannerImage] = React.useState(true);
  const [validCoverImage, setValidCoverImage] = React.useState(true);

  const handleImageChangeCover = (e) => {
    const file = e.target.files[0];
    setImageCover(file);
    setCoverPreview(URL.createObjectURL(file));

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const fileSizeInBytes = file.size;
      const fileSizeInKB = (fileSizeInBytes / 1024).toFixed(2);
      const width = img.width;
      const height = img.height;
      console.log(`Cover Image dimensions: ${width}x${height}`);

      if (width === 500 && height === 400 && fileSizeInKB <= 1000) {
        setValidCoverImage(true);
      } else {
        setValidCoverImage(false);
      }
    };
  };

  const handleImageChangeBanner = (e) => {
    const file = e.target.files[0];
    setImageBanner(file);
    setBannerPreview(URL.createObjectURL(file));

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const fileSizeInBytes = file.size;
      const fileSizeInKB = (fileSizeInBytes / 1024).toFixed(2);
      const width = img.width;
      const height = img.height;
      console.log(`Banner Image dimensions: ${width}x${height}`);

      if (width === 1280 && height === 800 && fileSizeInKB <= 1000) {
        setValidBannerImage(true);
      } else {
        setValidBannerImage(false);
      }
    };
  };

  const handleAddInclusion = () => {
    setInclusions([...inclusions, '']);
  };

  const handleAddExclusion = () => {
    setExclusions([...exclusions, '']);
  };

  const handleAddPrice = () => {
    setPriceDescription([...priceDescription, ''])
  }
  const deletePriceObj = (index) => {
    console.log(index);
    if(priceDescription.length > 1){
      const updatedPriceDescription=priceDescription.filter((val,id)=> id !== index);
      setPriceDescription(updatedPriceDescription)
    }
  }
  const handleInclusionChange = (index, event) => {
    const newInclusions = [...inclusions];
    newInclusions[index] = event.target.value;
    setInclusions(newInclusions);
  };
  const deleteInclusionChange = (index) => {
    if (inclusions.length > 1) {
      const updatedInclusions = inclusions.filter((val, id) => id !== index);
      console.log(updatedInclusions);
      setInclusions(updatedInclusions);
    }
  };

  const handleExclusionChange = (index, event) => {
    const newExclusions = [...exclusions];
    newExclusions[index] = event.target.value;
    setExclusions(newExclusions);
  };
  const deleteExclusionChange = (index) => {
    if (exclusions.length > 1) {
      const updatedExclusions = exclusions.filter((val, id) => id !== index);
      console.log(updatedExclusions);
      setExclusions(updatedExclusions);
    }
  }

  //  ------------------------------------Data structure that send to API-----------------

  const [previewPage, setPreviewPage] = React.useState(false);
  const previewPageOpen = () => {
    if (validBannerImage & validCoverImage) {
      setPreviewPage(true);
    }
    else {
      alert("Please select valid Image");
      return;
    }
  }
  const previewPageClose = () => {
    setPreviewPage(false);
    close();
  }
  const Uppercase = (str) => str?.toUpperCase();
  const capitalize = (str) => {
    if (typeof str !== 'string') return '';
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  };

  return (
    <StyledTableContainer component={Paper} open={open}>
      <div className="admin-package-detail-container">
        <div className="admin-package-detail-page"
          style={{ background: "rgba(249, 249, 249, 1)" }}
        >
          <div className="back-and-text">
            <div className="back">
              <button onClick={close} style={{ background: "none", border: "none", cursor: "pointer" }}>
                <img src="backicon.svg" alt="back-icon" />
              </button>
            </div>
            <div className="text">
              <h2>Add New Attraction</h2>
            </div>
          </div>
          <form onSubmit={(e) => {
            e.preventDefault();
            previewPageOpen();
          }}>
            <div className="package-basic-details">
              <h3>Basic Details</h3>
              <div className="package-form-title-description">
                <div className="package-form-title">
                  <label>Title *</label>
                  <input type="text" placeholder="magical dubai" maxLength={60} value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="package-form-description">
                  <label>Description *</label>
                  <textarea type="text" placeholder="Very luxurious and comfortable" value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    required style={{ height: "100px", overflow: "hidden" }}
                  />
                </div>
              </div>
            </div>
            <div className="package-form-cover-banner-img">
              <div className="package-form-cover-img">
                <label>Add Cover Image *</label>
                <input className="file-input" style={{ borderRadius: "20px" }}
                  placeholder="Choose image"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChangeCover}
                  required={!coverPreview}
                />
                {!validCoverImage &&
                  <p className="uploading-cover-image">Cover image dimensions should be 500x400 pixels and less than 1000Kb</p>
                }
                {coverPreview && <div className="package-form-img">
                  <img src={coverPreview} alt="coverPreview" />
                </div>
                }
              </div>
              <div className="package-form-banner-img">
                <label>Add Banner Image *</label>
                <input className="file-input" style={{ borderRadius: "20px" }}
                  placeholder="Choose image"
                  type="file"
                  accept="image/*"
                  onChange={handleImageChangeBanner}
                  required={!bannerPreview}
                />
                {!validBannerImage &&
                  <p className="uploading-cover-image">Banner image dimensions should be 1280x800 pixels and less than 1000Kb</p>
                }
                {bannerPreview && <div className="package-form-img">
                  <img src={bannerPreview} alt="bannerPreview" />
                </div>
                }
              </div>
            </div>
            <div className="package-form-location-details">
              <h2>Location Details</h2>
              <div className="package-form-country-place">
                <div className="package-form-from-country">
                  <label>Country</label>
                  <select value={fromCountry} onChange={handleFromCountryChange} required>
                    <option value="" disabled>Select Country</option>
                    {country?.map((val, id) => {
                      return <option key={val._id} value={val._id}>{Uppercase(val.name)}</option>
                    })}

                  </select>
                </div>
                <div className="package-form-from-country">
                  <label>City</label>
                  <select value={place} onChange={handlePlaceChange} required>
                    <option value="" disabled>Select Place</option>
                    {city?.map((val, id) => {
                      return <option key={val._id} value={val._id}>{capitalize(val.name)}</option>
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="package-form-inclusion-exclusion">
              <h2>Cancellation Policy</h2>
              <div className="package-form-cancellation-polocy">
                <textarea placeholder="Write Cancellation Policy"
                  value={cancellationPolicy?.length > 0 ? cancellationPolicy?.join("\n") : cancellationPolicy}
                  onChange={(e) => {
                    // setCancellationPolicy(e.target.value);
                    setCancellationPolicy(e.target.value.split("\n"));
                  }}
                />
              </div>
            </div>
            <div className="package-form-from-country-attraction">
              <h2>Category *</h2>
              <select value={category} onChange={handleCategoryChange} required>
                <option value="" disabled>Select Category</option>
                {categoryData?.map((val, id) => {
                  return <>
                    <option value={val._id}>{val.name}</option>
                  </>
                })}
              </select>
            </div>
            <div className="package-form-inclusion-exclusion">
              <h2>Inclusion/Exclusions</h2>
              <div className="package-form-inclusion-exclusion-container">
                <div className="package-form-inclusion">
                  <label>Inclusions *</label>
                  {inclusions.map((inclusion, index) => (
                    <div className="inclusion-input-delete-btn">
                      <input
                        key={index}
                        type="text"
                        value={inclusion}
                        onChange={(event) => handleInclusionChange(index, event)}
                        required
                      />
                      <p
                      // className={index === 0 ? "display-none" : " "}
                      ><TiDelete
                          disabled={index === 0}
                          onClick={() => deleteInclusionChange(index)}
                        /></p>
                    </div>
                  ))}
                  <p style={{ width: "fit-content" }}
                    onClick={handleAddInclusion}
                  ><span>+</span> Add more</p>
                </div>
                <div className="package-form-inclusion">
                  <label>Exclusions *</label>
                  {exclusions.map((exclusion, index) => (
                    <div className="inclusion-input-delete-btn">
                      <input
                        key={index}
                        type="text"
                        value={exclusion}
                        onChange={(event) => handleExclusionChange(index, event)}
                        required
                      />
                      <p
                      // className={index === 0 ? "display-none" : " "}
                      ><TiDelete
                          disabled={index === 0}
                          onClick={() => deleteExclusionChange(index)}
                        /></p>
                    </div>
                  ))}
                  <p style={{ width: "fit-content" }}
                    onClick={handleAddExclusion}
                  ><span>+</span> Add more</p>
                </div>
              </div>
            </div>
            {/* ---------------------Exception start--------- */}
            <div className="package-form-location-details">
              <h2>Meeting Point</h2>
              {expectation.map((val, id) => (
                <div key={id} className="package-form-country-place">
                  <div className="package-form-from-country">
                    <label>Location</label>
                    <input
                      type="text"
                      placeholder="Dubai..."
                      value={val.location}
                      onChange={(e) => handleLocationChange(id, e.target.value)}
                    // required
                    />
                  </div>
                  <div className="package-form-from-country">
                    <label>Description</label>
                    <textarea
                      type="text"
                      placeholder="we are testing inside dubai city tour"
                      style={{ height: "100px", overflow: "hidden" }}
                      value={val.description}
                      onChange={(e) => handleLdescriptionChange(id, e.target.value)}
                    />
                    <span id="landcombos-location-delete"><TiDelete onClick={() => deleteException(id)} /></span>
                  </div>
                  {/* <div className="package-form-from-country">
                    <label>Open Time</label>
                    <input
                      type="time"
                      value={val.time}
                      onChange={(e) => handleOpenTimeChange(id, e.target.value)}
                      style={{ width: "fit-content" }}
                      required
                    />
                  </div> */}
                </div>
              ))}
              <p style={{ cursor: "pointer", width: "fit-content" }}
                onClick={handleAddException}
              ><span style={{ color: "#8BC4FF", fontSize: "24px" }}>+</span> Add More</p>
            </div>
            {/* --------------------------Exception end------------- */}
            {/* -------------------------------Price start-------------- */}
            <div className="package-form-price">
              <h2>Pricing</h2>
              {priceDescription.map((val, id) => (
                <div key={id} style={{ display: "flex", gap: "4rem", marginBottom: "1rem" }}>
                  <div className="price-description-input">
                    <label>Description</label>
                    <textarea
                      type="text"
                      placeholder="Add description"
                      value={val.description}
                      maxLength={100}
                      onChange={(e) => handleDescriptionChange(id, e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <label>Adults Price</label>
                    <div className="package-form-price-value">
                      <select
                        value={val.currency}
                        onChange={(e) => handleCurrencyChange(id, e.target.value)}
                        required
                        style={{ width: "100px" }}
                      >
                        {/* <option value="" disabled>Currency</option> */}
                        <option value="AED">AED</option>
                      </select>
                      <input
                        type="number"
                        placeholder="1200"
                        min={10} max={10000}
                        value={val.adultPrice}
                        onChange={(e) => handleAdultPriceChange(id, parseInt(Number(e.target.value)))}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label>Children Price</label>
                    <div className="package-form-price-value">
                      <select
                        value={val.currency}
                        onChange={(e) => handleCurrencyChange(id, e.target.value)}
                        required
                        style={{ width: "100px" }}
                      >
                        {/* <option value="" disabled>Currency</option> */}
                        <option value="AED">AED</option>
                      </select>
                      <input
                        type="number"
                        placeholder="1200"
                        min={10} max={10000}
                        value={val.childPrice}
                        onChange={(e) => handleChildPriceChange(id, parseInt(Number(e.target.value)))}
                        required
                      />
                    </div>
                  </div>
                  <span id="attraction-price-delete"><TiDelete onClick={() => deletePriceObj(id)} /></span>
                </div>
                
              ))}
              <br />
              <p onClick={handleAddPrice} style={{ cursor: "pointer", width: "fit-content" }}><span
                style={{ color: "#8BC4FF", fontSize: "24px" }}>+</span>Add More</p>
            </div>
            {/* ------------------------------- Price End-----------*/}
            <div className="package-form-back-next-btn">
              <p id="back" onClick={close}>Back</p>
              <button id="next"
              // onClick={() => {
              // }}
              >Next</button>
            </div>
          </form>
        </div>
      </div>
      {previewPage && <PackageDetail close={previewPageClose} closeform={close}
        attractionEditedId={attractionEditedId}
        title={title} description={description}
        coverImage={coverPreview} bannerImage={bannerPreview} imageCover={imageCover} imageBanner={imageBanner}
        imageCoverP={coverPreview} imageBannerP={bannerPreview}
        fromCountry={fromCountry} fromCountryName={fromCountryName}
        place={place} placeName={placeName}
        inclusion={inclusions} exclusion={exclusions}
        category={category} categoryName={categoryName}
        // location={location} Ldescription={Ldescription} openTime={openTime}
        currency={currency} loadPackagesList={loadPackagesList}
        priceDescription={priceDescription} expectation={expectation}
        cancellationPolicy={cancellationPolicy}
      />}
    </StyledTableContainer >
  );
};

export default CreatePackageForm;


// -------------------------------------------



